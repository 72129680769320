import axios from 'axios'

import Axios from 'axios'
import { Loading, Message } from 'element-ui'

// http请求拦截器
let loading
// Axios.interceptors.request.use(config => {
//   // element ui Loading方法
//   loading = Loading.service({ fullscreen: true })
//
//   return config
// }, error => {
//   loading.close();
//
//   return Promise.reject(error)
// })

// http响应拦截器
Axios.interceptors.response.use(data => {
  //console.log(data.data)
  // 响应成功关闭loading
 // loading.close();
  const code = data.data.code;
  if ( code == "-3" ) {
    Message.error(data.data.msg)
  }
  return data
}, error => {
 // loading.close();
  return Promise.reject(error)
})

// 导出封装的请求方法
export default {
  get(url , param) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: param
      }).then((res) => {
        // 响应成功resolve返回同步数据
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  post(url , param) {
    return new Promise((resolve , reject) => {
      axios.post(url , param).then((res) => { // axios请求会自动将请求体中的数据以json格式进行传输
        resolve(res.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  put(url , param) {
    return new Promise((resolve , reject) => {
      axios.put(url , param).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  delete(url , param) {
    return new Promise((resolve , reject) => {
      axios.delete(url , {
        params: param
      }).then((res) => {
        resolve(res.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  uploadFile(url , param) {
    return new Promise((resolve , reject) => {
      axios({
        method: 'post',
        url: url,
        data: param,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      });
    })
  }

}
