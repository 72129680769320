import Vue from "vue"

//导出公共常量
export default {
  // 响应码
  CONSTANT: {
    USER_OPERATE_TYPE: {
      CLICK:  0 ,
      COMMENT:  1 ,
      THUMB: 2 ,
      COLLECT: 3 ,
      APPRECIATE: 4
    },
    RESPONSE_CODE: {
      SUCCESS: 0,
      FAILED: 1,
      EXCEPTION: -1
    },
    BLOG_FLAG: {
      RELEASE: {
        CODE: 0,
        MSG: '完结'
      } ,
      DRAFT: {
        CODE: 1,
        MSG: '草稿'
      },
    },
    PUBLISH_STATUS: {
      PUBLISHED: {
        CODE: 0,
        MSG: '已发布'
      } ,
      UNPUBLISHED: {
        CODE: 1,
        MSG: '未发布'
      },
    },
    BLOG_OPERATE_STATUS: {  // 博客操作状态 推荐 赞赏 评论
      YES: {
        CODE: 0,
        MSG: '已开启'
      } ,
      NO: {
        CODE: 1,
        MSG: '未开启'
      },
    },
    SHARE_STATEMENT: {
      ORIGINAL: {
        CODE: 1,
        MSG: "原创"
      },
      REPRINT: {
        CODE: 2,
        MSG: "转载"
      },
      TRANSLATE: {
        CODE: 3,
        MSG: "翻译"
      }
    }
  }
}
