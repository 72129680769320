import Vue from 'vue'
import VueRouter from 'vue-router'
import Blog from '../views/Blog'
import SearchModel from '../components/model/SearchModel'

const Welcome = () => import('../views/admin/Welcome')
const AdminHome = () => import('../components/layout/AdminHome')


const Users = () => import('../views/admin/users/Users')
const Comments = () => import( '../views/admin/users/Comments')

const Report = () => import('../views/admin/dataWatch/Report')
const Report2 = () => import('../views/admin/dataWatch/Report2')

const Login = () => import('../views/admin/Login.vue')
const NotFound = () => import('../views/NotFound.vue')

const Home = () => import('../views/Home.vue')
const Types = () => import('../views/type/Types.vue')
const Tags = () => import('../views/type/Tags.vue')

const Link = () => import('../views/Link.vue')
const Archives = () => import('../views/Archives.vue')
const Message = () => import('../views/Message.vue')


const PostBlogs = () => import('../views/admin/blogs/PostBlogs.vue')
const Blogs = () => import('../views/admin/blogs/Blogs.vue')
const Types2 = () => import('../views/admin/blogs/Types2.vue')
const Tags2 = () => import('../views/admin/blogs/Tags2.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: 'home' },
  { path: '/login', component: Login },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchModel
  },
  {
    path: '/Blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/Types',
    name: 'Types',
    component: Types
  },
  {
    path: '/Tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/Archives',
    name: 'Archives',
    component: Archives
  },
  {
    path: '/Link',
    name: 'Link',
    component: Link
  },
  {
    path: '/Message',
    name: 'message',
    component: Message
  },
 {
    path: '/AdminHome',
    component: AdminHome,
    redirect: '/welcome',
    children: [{ path: '/welcome', component: Welcome },
      { path: '/users', component: Users },
      { path: '/comments', component: Comments },
      { path: '/PostBlogs', component: PostBlogs },
      { path: '/Blogs', component: Blogs },
      { path: '/tags2', component: Tags2 },
      { path: '/types2', component: Types2 },
      { path: '/report', component: Report },
      { path: '/report2', component: Report2 }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]
const router = new VueRouter({
  mode: 'hash',
  routes
})

// 挂载全局路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转

  // 后端管理页面挂载路由首位
  if (to.path === '/AdminHome' || to.path === '/welcome' || to.path === '/users'  || to.path === '/comments' || to.path === '/PostBlogs'
    || to.path === '/blogs' || to.path === '/tags2' || to.path === '/types2' || to.path === '/Report' || to.path === '/Report2') {
    // 当前用户判断
    if ( !sessionStorage.getItem("onlineUser") ) {
      // 用户未登录
      next("/login");
    }
  }

  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 后端指定接口验证了token的正确性
 //  if (!tokenStr && (to.path === '/blogs')) return next('/login') 暂时关闭路由守卫
  next()
})
export default router
