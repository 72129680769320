<template>
  <div class="home">
    <div class="blog-banner banner">
      <h1 class="banner-title">博客详情</h1>
    </div>
    <!--中间内容-->
    <div id="waypoint" class="m-container-blog m-blog animated fadeInLeft">
      <div class="ui container">
        <div class="ui top attached segment">
          <div class="ui horizontal link list">
            <div class="item">
              <img src="https://wsong.oss-cn-hangzhou.aliyuncs.com/%20song-blog/blog-main-image/%20831724696538157056%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220414105642.jpg" width="50%" class="ui avatar image"/>
              <div class="content"><a class="header"
                                      style="font-size: 17px;margin-left: 20px">想听K歌之王</a>
              </div>
            </div>
            <div class="item" style="margin-left: 30px">
              <span>分类：</span> {{ currentBlog.type.typeName }}
            </div>
            <div class="item" style="margin-left: 30px">
              <i class="calendar icon"></i> {{ currentBlog.createTime }}
            </div>
            <div class="item" style="margin-left: 30px">
              <i class="eye icon"></i> {{ currentBlog.views }}
            </div>
            <div class="item" style="margin-left: 30px">
              <i class="thumbs up outline icon"></i> {{ currentBlog.thumbs }}
            </div>
            <div class="item" style="margin-left: 30px">
              <svg t="1647083290761" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2228" width="18" height="18">
                <path
                  d="M512 720.416l232.096 171.904a19.2 19.2 0 0 0 16.864 2.88c9.536-2.88 14.592-12.224 11.84-20.864l-88.96-281.6 237.344-176.96a16.48 16.48 0 0 0 6.816-13.152c0-9.152-7.904-16.928-18.112-16.96l-292-0.448-88.48-277.12a17.568 17.568 0 0 0-11.776-11.264c-9.856-2.976-20.16 2.304-23.04 11.264l-88.48 277.12-292 0.48a18.624 18.624 0 0 0-14.624 6.944 16.096 16.096 0 0 0 3.328 23.136l237.376 176.96-88.992 281.6a15.904 15.904 0 0 0 2.72 14.688c6.048 7.744 17.856 9.312 25.984 3.296L512 720.416z m-194.016 223.36a83.008 83.008 0 0 1-114.56-15.424 79.904 79.904 0 0 1-13.28-73.28l75.296-238.24-200.864-149.76a80.096 80.096 0 0 1-15.424-113.92 82.624 82.624 0 0 1 64.864-31.456l245.312-0.384 74.304-232.672c13.6-42.56 59.52-66.112 102.56-53.024A81.536 81.536 0 0 1 590.4 88.64l74.304 232.64 245.312 0.416c45.184 0.064 82.08 36.16 82.016 81.024a80.48 80.48 0 0 1-32.576 64.352l-200.864 149.76 75.296 238.24c13.568 42.976-11.072 88.352-54.496 101.408a83.2 83.2 0 0 1-73.344-12.736L512 800.064l-194.016 143.68z"
                  p-id="2229">
                </path>
              </svg>
              {{ currentBlog.collectCount }}
            </div>
          </div>
        </div>
<!--        <div class="ui attached segment">
          &lt;!&ndash;图片区域&ndash;&gt;
          <img v-bind:src=currentBlog.firstPictureUrl class="ui fluid rounded image">
        </div>-->
        <div class="ui  attached padded segment">
          <!--内容-->
          <!-- 分享状态 -->
          <!--          <div class="ui right aligned basic segment">
                      <div class="ui orange basic label">{{currentBlog.shareStatement}}</div>
                    </div>-->
          <h2 class="ui aligned header" v-text="currentBlog.title"></h2>
          <br>
          <div id="blogContent" class="typo  typo-selection js-toc-content m-padded-lr-responsive m-padded-tb-large"
               v-html="currentBlog.content" style="width: 100%">

          </div>

          <!--标签-->
          <div class="m-padded-lr-responsive">
            <!--            <div class="ui basic teal left pointing label" v-for="item in tagList" :key="item.tagId">{{item.tagName}}</div>-->
          </div>

          <!--赞赏-->
          <div class="ui center aligned basic segment">
            <el-popover
              placement="left"
              width="400"
              trigger="click">
              <div class="ui orange basic label" v-if="this.currentBlogStatus.appreciation">
                <div class="ui images">
                  <div class="image">
<!--                    <img src="../assets/images/backgournd/type.jpg" alt="" class="ui rounded bordered image"
                         style="width: 400px">-->
                    <div>支付宝</div>
                  </div>
                  <div class="image">
<!--                    <img src="../assets/images/backgournd/type.jpg" alt="" class="ui rounded bordered image"
                         style="width: 400px">-->
                    <div>微信</div>
                  </div>
                </div>
              </div>
              <el-button slot="reference" class="ui orange basic circular button" v-on:click="appreciate()">赞 赏
              </el-button>
            </el-popover>
            <button v-on:click="thumbs()" class="ui orange basic circular button" v-if="thumbsFlag">点 赞</button>
            <button v-on:click="unThumbs()" class="ui orange basic circular button" v-if="unThumbsFlag">取消点赞</button>
          </div>

        </div>
        <div class="ui attached positive" style="margin-left: 1vw;margin-bottom: 3vh">
          <!--博客信息-->
          <div class="ui middle aligned grid">
            <div class="eleven wide column">
              <ui class="list">
                <li>作者：想听K歌之王</li>
                <li>发表时间：{{ currentBlog.createTime }}</li>
                <li>版权声明：自由转载</li>
              </ui>
            </div>
          </div>
        </div>
        <div id="comment-container" class="ui bottom attached segment">
          <!--留言区域列表-->
          <div class="ui blue segment">
            <div class="ui threaded comments" style="max-width: 100%">
              <h3 class="ui dividing header">评论：</h3>
              <!--父级评论-->
<!--              <div class="comment" v-for="parentItem in currentBlogCommentList" :key="parentItem.id">
                <a class="avatar">
                  <img v-bind:src=parentItem.user.avatar>
                </a>
                <div class="content">
                  <a class="author">{{ parentItem.user.nickname }}</a>
                  <div class="metadata">
                    <span class="date">{{ parentItem.createTime }}</span>
                  </div>
                  <div class="text" v-text="parentItem.content">
                  </div>
                  <div class="actions">
                    <a class="reply" @click="replyComment(parentItem)">回复</a>
                    <a class="reply" @click="deleteComment(parentItem)" v-show="parentItem.creatorId === user.id">删除</a>
                  </div>
                </div>
                &lt;!&ndash;子级评论模块&ndash;&gt;
                <div class="comments">
                  <div class="comment" v-for="childItem in parentItem.children" :key="childItem.id">
                    <a class="avatar">
                      <img v-bind:src=childItem.user.avatar>
                    </a>
                    <div class="content">
                      <a class="author">{{ childItem.user.nickname }}</a>
                      <div class="metadata">
                        <span class="date">{{ childItem.createTime }}</span>
                      </div>
                      <div class="text" v-text="childItem.content">
                      </div>
                      <div class="actions">
                        <a class="reply" @click="replyComment(childItem)">回复</a>
                        <a class="reply" @click="deleteComment(childItem)"
                           v-show="childItem.creatorId === user.id">删除</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
          <div class="ui form">
            <el-form ref="addForm" :model="commentFormData">
              <div class="field">
                <textarea name="content"
                          v-bind:disabled="currentBlog.commentable === this.$constant.BLOG_OPERATE_STATUS.NO.CODE"
                          v-model="commentFormData.content"></textarea>
              </div>
            </el-form>
            <div class="fields" style="margin-left: 43vw;margin-top: 2vh">
              <div class="field  m-margin-bottom-small m-mobile-wide">
                <button class="ui blue button m-mobile-wide" @click="addComment"><i class="edit icon"></i>发布
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

<!--    <div id="toolbar" class="m-padded m-fixed m-right-bottom">
      <div class="ui vertical icon buttons ">
        <button type="button" class="ui toc blue button">目录</button>
        <a href="#comment-container" class="ui blue button">留言</a>
        <button class="ui wechat icon button"><i class="weixin icon"></i></button>
        <button class="ui icon button" @click="thumbs()">
          <i v-if="thumbsFlag" class="thumbs up icon"></i>
          <i v-else class="thumbs up outline icon"></i>
        </button>
        <div id="toTop-button" class="ui icon button"><i class="chevron up icon"></i></div>
      </div>
    </div>-->
    <div class="ui toc-container flowing popup transition hidden" style="width: 250px!important;">
      <ol class="js-toc">
      </ol>
    </div>

    <div id="qrcode" class="ui wechat-qr flowing popup transition hidden " style="width: 130px !important;">
      <!--      <img src="../assets/images/wechat.jpg" alt="" class="ui rounded image" style="width: 120px !important;">-->
    </div>

    <br>
    <br>
    <Footer></Footer>
  </div>

</template>
<script>
import Prism from '../assets/lib/prism/prism'
// import QRCode from '../assets/lib/qrcode/qrcode.min.js'
import QRCode from 'qrcodejs2'
import Footer from '../components/layout/Footer'
// markdown 语法转义
import {marked} from 'marked'
import {Notification} from "element-ui";

export default {
  // 注册组件
  components: {
    Footer
  },
  data() {
    return {
      thumbsFlag: '',
      unThumbsFlag: '',
      modal: false,
      commentFormData: {
        blogId: '',
        content: '请输入评论信息...', // 评论内容
        parentCommentId: ''
      },
      //当前博客的评论赞赏状态
      currentBlogStatus: {
        appreciation: false,
        comment: false
      },
      userId: '',   // 当前用户Id
      user: {},  // 当前登录用户
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      currentBlog: {},  // 当前点击博客vo
      currentBlogCommentList: [], // 当前博客所有评论集合
    }
  },
  // get 请求和 post put delete请求的区别
  // get 请求在请求行 其余请求在请求体 get 请求只能请求字符串类型、其余请求可以请求任意类型
  // get 请求字符串类型有长度限制、其余请求请求字符串类型无长度限制
  // 最重要的区别：get请求会被浏览器所缓存、其余请求不会被浏览器所缓存。 解决get请求缓存 使用时间戳进行拼接即可
  created() {
    this.addBlogViews();
    this.getUserThumbsStatus();
    // 判断是否是首次加载
    if ( this.$route.query.firstVisitFlag == this.$constant.RESPONSE_CODE.SUCCESS ) {
      // 用户首次访问提示信息
      Notification({
        title: '首次查看',
        message: '首次查看博客，可能会出现代码块样式丢失（解决中），背景加载完毕刷新即可，请见谅~',
        duration: 0,
        type: "success",
      })
    }
  },
  methods: {

    //获取用户对这篇博客的点赞状态
    async getUserThumbsStatus() {
      const res = await this.$http.get("/user/getUserBlogOperate", {
        userId: sessionStorage.getItem("onlineUser") ? JSON.parse( sessionStorage.getItem("onlineUser") ).id : null, // TODO: 用户信息待修改, // TODO: 用户信息待修改
        blogId: this.$route.query.blogId,
        operateType: this.$constant.USER_OPERATE_TYPE.THUMB
      });

      if (res.data && res.data > 0) {
        // 用户点赞记录大于0
        return this.unThumbsFlag = true;
      }
      this.thumbsFlag = true;
    },

    // 进入博客详情页面，浏览数加1
    async addBlogViews() {
      this.$http.post("/user/insertUserBlogOperate", {
        userId: sessionStorage.getItem("onlineUser") ? JSON.parse( sessionStorage.getItem("onlineUser") ).id : null, // TODO: 用户信息待修改, // TODO: 用户信息待修改
        blogId: this.$route.query.blogId,
        operateType: this.$constant.USER_OPERATE_TYPE.CLICK
      }).then(() => {
        this.getCurrentBlog();
      });
    },
    async thumbs() {
      // 判断用户是否登录
      const userBlogOperate = {
        userId: sessionStorage.getItem("onlineUser") ? JSON.parse( sessionStorage.getItem("onlineUser") ).id : null, // TODO: 用户信息待修改
        blogId: this.$route.query.blogId,
        operateType: this.$constant.USER_OPERATE_TYPE.THUMB
      }
      // 点赞接口
      const res = await this.$http.post("/user/insertUserBlogOperate", userBlogOperate);
      if (res.code === this.$constant.RESPONSE_CODE.SUCCESS && res.data === 1) {
        this.unThumbsFlag = true;
        this.thumbsFlag = false;
        this.getCurrentBlog();
        return this.$message.success("点赞成功，谢谢支持！");
      }
      return this.$message.error("点赞失败，请稍后重试~");
    },

    async unThumbs() {
      // 判断用户是否登录
      const userBlogOperate = {
        userId: sessionStorage.getItem("onlineUser") ? JSON.parse( sessionStorage.getItem("onlineUser") ).id : null, // TODO: 用户信息待修改, // TODO: 用户信息待修改
        blogId: this.$route.query.blogId,
        operateType: this.$constant.USER_OPERATE_TYPE.THUMB
      }
      //取消点赞
      const res = await this.$http.delete("/user/deleteUserBlogOperate", userBlogOperate);
      if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
        this.unThumbsFlag = false;
        this.thumbsFlag = true;
        this.getCurrentBlog();
        return this.$message.info("取消成功！");
      }
      return this.$message.error("取消失败，请稍后重试~");
    },


    /**
     * 赞赏博客
     * @returns {ElMessageComponent}
     */
    appreciate() {
      if (this.currentBlog.appreciation === this.$constant.BLOG_OPERATE_STATUS.NO.CODE) {
        return this.$message.info("博主未开启赞赏功能~");
      }
      this.currentBlogStatus.appreciation = true;
    },
    toLogin() {
      const tokenStr = window.sessionStorage.getItem('token')
      // 后端指定接口验证了token的正确性
      if (!tokenStr) {
        this.$confirm('登录后才能发表评论或者点赞，请问是否先登录？', '提示', { // 确认框
          type: 'info'
        }).then(() => {
          this.$router.push('/login')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '你选择不登录'
          })
          return false
        })
      }
      return !!tokenStr
    },

    async addComment() {
      if (this.currentBlog.commentable === this.$constant.BLOG_OPERATE_STATUS.NO.CODE) {
        return this.$message.error("博主关闭了评论功能~");
      }
    },

    reloadPrism() {
      process.browser && document.querySelectorAll('pre code').forEach(block => Prism.highlightElement(block))
    },

    // 获取当前点击的博客vo
    async getCurrentBlog() {
      const currentBlogId = this.$route.query.blogId;
      const param = {
        id: currentBlogId,
        published: this.$constant.PUBLISH_STATUS.PUBLISHED.CODE
      }
      const res = await this.$http.get('/blog/listBlogs', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        this.$message.error('获取博客列表失败！')
      }
      this.currentBlog = res.data.list[0]
      // 博客内容转义 markdown =》 HTML
      this.currentBlog.content = marked(this.currentBlog.content)
    },

/*    getUser() {
      this.user = window.sessionStorage.getItem('user')
      if (this.user != null) {
        this.userId = JSON.parse(this.user).userId
        this.nickname = JSON.parse(this.user).nickname
      }
    },*/
    logout() {

    }
  },
  mounted() {
    // 有效
    setTimeout(() => {
      this.reloadPrism()
      tocbot.init({
        // Where to render the table of contents.
        tocSelector: '.js-toc',
        // Where to grab the headings to build the table of contents.
        contentSelector: '.js-toc-content',
        // Which headings to grab inside of the contentSelector element.
        headingSelector: 'h1, h2, h3'
      })
    }, 1000)
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
    $('#payButton').popup({
      popup: $('.payQR.popup'),
      on: 'click',
      position: 'bottom center'
    })
    $('.toc.button').popup({
      popup: $('.toc-container.popup'),
      on: 'click',
      position: 'left center'
    })
    $('.wechat').popup({
      popup: $('.wechat-qr'),
      position: 'left center'
    })
    $('#toTop-button').click(function () {
      //console.log('111')
      $(window).scrollTo(0, 500)
    })
    var qrcode = new QRCode('qrcode', {
      text: 'https://blog.csdn.net/Dlihcsong',
      width: 110,
      height: 110,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H
    })
  }
}
</script>

<style scoped>
@import "../assets/css/typo.css";
@import "../assets/css/animate.css";

.container {
  animation: main 1s;
}

.m-blog {
  padding-top: 70vh !important;
  padding-bottom: 0px !important;
}

.blog-banner {
  height: 67vh;
  background: url("https://wsong.oss-cn-hangzhou.aliyuncs.com/%20song-blog/blog-main-image/%20823451797803057152blogDetail.jpg") center center / cover no-repeat;
  background-color: white;
}

div {
  border-style: none!important;
}
</style>
